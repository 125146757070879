import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { Screen, ScreenService } from '../screen.service';

@Injectable({
    providedIn: 'root'
})
export class ConnectGuardService implements CanActivate {
    path: import('@angular/router').ActivatedRouteSnapshot[];
    route: import('@angular/router').ActivatedRouteSnapshot;

    constructor(public router: Router, private screenService: ScreenService) { }

    canActivate(): boolean {
        let can = false;
        const s = this.screenService.getScreenFromLocalStorage();
        if (s) {
            if (s.connectedOn) {
                this.router.navigateByUrl('/player-v2');
            } else {
                can = true;
            }

        } else {
            can = true;
        }
        return can;
    }
}
