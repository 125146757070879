import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';

export class Screen {
  uuid: string;
  authKey: string;
  connectedOn: string;
  name: string;
  rotation: number;
  mediaChangeOn: string;
  timetableChangeOn: string;
}

@Injectable({
  providedIn: 'root'
})
export class ScreenService {

  screen = new BehaviorSubject<Screen>(new Screen());
  screenId = new BehaviorSubject<string>(null);
  loading = new BehaviorSubject<any>(null);
  error = new BehaviorSubject<any>(null);
  item: any;
  itemDoc: any;
  uuid = new BehaviorSubject<string>(null);

  constructor(
    private firestore: AngularFirestore
  ) {

   }

   makeUUIDScreen() {
     this.firestore.collection('screens').doc(this.uuid.value).get().subscribe(
       (res) => {
         if (res.exists) {
          this.uuid.next(this.generateUUID());
          this.makeUUIDScreen();
         } else {
           this.createScreen();
         }
       }
     );
   }



  generateUUID(): string {
    const isString = `${this.S4()}-${this.S4()}-${this.S4()}`;

    return isString;
  }

  private S4(): string {
    // tslint:disable-next-line: no-bitwise
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1).toUpperCase();
  }

  createScreen() {

    const s = {
      uuid: this.uuid.value,
      connectedOn: null
    };

    this.firestore.collection('screens').doc(this.uuid.value).set(s)
      .then((docRef) => {
        this.loading.next(null);
        const screenId = this.uuid.value;
        localStorage.setItem('screenId', screenId);
        this.subscribeToScreen(screenId);
      })
      .catch((error) => {
        this.loading.next(null);
        this.error.next(error);
      });
  }

  updateLocalScreenData(screen) {
    this.screen.next(screen);
    localStorage.setItem('screen', screen);
  }

  subscribeToScreen(id) {
    this.screenId.next(id);
    this.itemDoc = this.firestore.doc<any>('screens/' + id);
    this.item = this.itemDoc.valueChanges().subscribe((datas) => {
      localStorage.setItem('screen', JSON.stringify(datas));
      this.screen.next(datas);
      // // Send message to flutter
      // if ((window as any).FirebaseScreen) {
      //   (window as any).FirebaseScreen.postMessage(JSON.stringify(datas));
      // } else {
      //   console.log(':: ERROR :: Flutter Javascript Message Push channel not found');
      // }
    }, (err) => {
      console.log('error on firebase screen subscription : ', err);
    });

  }

  readScreens() {
    return this.firestore.collection('screens').snapshotChanges();
  }

  updateScreen(recordId, record) {
    this.firestore.doc('screens/' + recordId).update(record);
  }

  deleteScreen(recordId) {
    this.firestore.doc('screens/' + recordId).delete();
  }

  getScreenFromLocalStorage(): Screen {
    const screenString: string = localStorage.getItem('screen');
    const screen: Screen = screenString ? screenString !== 'undefined' ? JSON.parse(screenString) : null : null;
    return screen;
  }
}
