import { Component, OnInit, Input, ViewChild, OnDestroy, Output, EventEmitter } from '@angular/core';
import { MediaItem } from '../player-v2/models/media-item.class';
import { environment } from '@environment';
import { VgAPI } from 'videogular2/compiled/core';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit, OnDestroy {

  video: HTMLVideoElement;
  azureCDN = environment.azureCDN;
  api: VgAPI;
  api$: any;
  env = environment.identifier;


  @Input() mediaItems: MediaItem[];
  @Input() useLocalSrc: boolean;
  @Input() loop: boolean;

  // tslint:disable-next-line: no-output-on-prefix
  @Output() onPlayerEnded: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.api$.unsubscribe();
  }

  onPlayerReady(api: VgAPI) {
    this.api = api;

    // watch for video ended event
    this.api$ = this.api.getDefaultMedia().subscriptions.ended.subscribe(
      () => {
        // emit ended event
        this.onPlayerEnded.emit(true);
      }
    );
  }

  canPlay(event) { }

  getCDNUrl(publicUrl) {
    // Why? because we dont have the companyId: https:/....net/[[companyId]]/[[fileId]]
    return publicUrl.replace('https://iascreenmanager.blob.core.windows.net/', environment.azureCDN);
  }

}
