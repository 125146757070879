import { Component, OnInit, HostListener, ApplicationRef } from '@angular/core';
import { Observable, Observer, fromEvent, merge, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ScreenService, Screen } from './screen.service';
import { SwUpdate } from '@angular/service-worker';
import { environment } from '@environment';
import { concat, interval } from 'rxjs';
import { first } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isOnline = false;
  screen: Screen;
  screen$: any;
  orientation: ScreenOrientation;
  windowHoverTimeout: any;
  environment: any;
  showPWAEnforcer = false;
  rotation =  {
    landscape: true,
    portrait: false,
    landscapeInverted: false,
    portraitInverted: false
  };

  constructor(
    private router: Router,
    private screenService: ScreenService,
    private appRef: ApplicationRef,
    public updates: SwUpdate,
    private deviceService: DeviceDetectorService
  ) {
    this.createOnline$().subscribe(isOnline => this.isOnline = isOnline);
    this.environment = environment;
    this.orientation = window.screen.orientation; // mobile orientation
    updates.available.subscribe(event => {
      if (event.current !== event.available) {
        updates.activateUpdate().then(() => this.updateApp());
      }
    });

    // Allow the app to stabilize first, before starting polling for updates with `interval()`.
    const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
    const everyMinute$ = interval(60000);
    const everyMinuteOnceAppIsStable$ = concat(appIsStable$, everyMinute$);
    // TODO: APP sometimes nevers tabilizes, above not working
    everyMinute$.subscribe(() => {
      updates.checkForUpdate();
    });
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event) {
    this.orientation = window.screen.orientation;
  }

  ngOnInit() {   
    const screen = this.screenService.getScreenFromLocalStorage();
    this.setRotation();
    const screenId: string = localStorage.getItem('screenId');
    if (screen && screenId) {
      this.screenService.subscribeToScreen(screenId);
    }
    this.screen$ = this.screenService.screen.subscribe(
      (s) => {
        this.screen = s;
        this.setRotation();
      }
    );
    // this.showBottomBarTimed();

    const isInStandaloneMode = () =>
      // tslint:disable-next-line:max-line-length
      (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator['standalone']) || document.referrer.includes('android-app://');

    if (isInStandaloneMode()) {
      this.showPWAEnforcer = false;
    } else {
      if (this.deviceService.isMobile()) {
        this.showPWAEnforcer = true;
      }
    }
  }

  createOnline$() {
    return merge<boolean>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      }));
  }

  clearLocalStorage() {
    if (!confirm('Reset Player?')) { return; }
    localStorage.clear();
    this.router.navigateByUrl('/connect');
    this.updateApp();
  }

  currentYear() {
    return (new Date()).getFullYear();
  }

  updateApp() {
    document.location.reload();
  }

  setRotation() {
    if (!this.screen || !this.screen.rotation) { return; }
    console.log(this.screen)
    this.rotation.landscape = this.screen.rotation.toString() === "0" ? true : false;
    this.rotation.portrait = this.screen.rotation.toString() === "90" ? true : false;
    this.rotation.landscapeInverted = this.screen.rotation.toString() === "180" ? true : false;
    this.rotation.portraitInverted = this.screen.rotation.toString() === "270" ? true : false;
    console.log(this.rotation);
  }

  checkIsLandscape() {
    if ((this.screen.rotation >= 0  && this.screen.rotation < 90 ) || (this.screen.rotation >=  180 && this.screen.rotation < 270) || (this.screen.rotation > 315  && this.screen.rotation === 360)) {
      return true;
    } else {
      return false;
    }
  }

  checkIsPortrait() {
    if ((this.screen.rotation >= 90 && this.screen.rotation < 180)) {
      return true;
    } else {
      return false;
    }
  }

  checkIsPortrait270() {
    if ((this.screen.rotation >= 270 && this.screen.rotation <= 315 )) {
      return true;
    } else {
      return false;
    }
  }

  /*if (this.data.screen.rotation < 0 || this.data.screen.rotation > 360) {
    this.data.screen.rotation = 0;
    this.data.screen.orientation = 'landscape';
    this.data.screen.isInverted = false;
  } else if (this.data.screen.rotation >= 0 && this.data.screen.rotation <= 45) {
    this.data.screen.orientation = 'landscape';
    this.data.screen.isInverted = false;
  } else if (this.data.screen.rotation > 45 && this.data.screen.rotation < 135) {
    this.data.screen.orientation = 'portrait';
    this.data.screen.isInverted = false;
  } else if (this.data.screen.rotation >= 135 && this.data.screen.rotation <= 180) {
    this.data.screen.orientation = 'landscape';
    this.data.screen.isInverted = true;
  } else if (this.data.screen.rotation > 180 && this.data.screen.rotation < 225) {
    this.data.screen.orientation = 'landscape';
    this.data.screen.isInverted = true;
  } else if (this.data.screen.rotation >= 225 && this.data.screen.rotation <= 315) {
    this.data.screen.orientation = 'portrait';
    this.data.screen.isInverted = true;
  } else if (this.data.screen.rotation > 315 && this.data.screen.rotation <= 360) {
    this.data.screen.orientation = 'landscape';
    this.data.screen.isInverted = false;*/


}
