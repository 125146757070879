import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-fullscreen-toggler',
  templateUrl: './fullscreen-toggler.component.html',
  styleUrls: ['./fullscreen-toggler.component.scss']
})
export class FullscreenTogglerComponent implements OnInit {

  constructor() { }

  fullscreen = false;

  @HostListener('document:fullscreenchange', []) onFullscreenChange() { this.fullscreen = !this.fullscreen; }
/*   @HostListener('webkitfullscreenchange', ['$event'])
  @HostListener('mozfullscreenchange', ['$event'])
  @HostListener('MSFullscreenChange', ['$event'])
 */
  ngOnInit() {
  }

  fullscreenAvailable(): boolean {
    return document.fullscreenEnabled;
  }


  openFullscreen() {
    if (this.fullscreenAvailable()) {
      document.documentElement.requestFullscreen();
    }
  }

  exitFullscreen() {
    if (this.fullscreenAvailable()) {
      document.exitFullscreen();
    }
  }

  toggleFullscreen() {
    if (this.fullscreen) {
      this.exitFullscreen();
    }
    if (!this.fullscreen) {
      this.openFullscreen();
    }
  }

}
