import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'currencyNumber'
})
export class CurrencyNumberPipe implements PipeTransform {

    transform(val: any, currency: string, fractionDigits: number): string {
        if (val !== undefined && val !== null) {
            return parseInt(val).toLocaleString('de-CH', {
                style: 'currency',
                currency,
                minimumFractionDigits: fractionDigits
            });
        } else {
            return '';
        }
    }

}