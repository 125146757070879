import {Media} from '../models/media.class';
import {Timetable} from '../models/timetable.class';
import * as moment from 'moment';
import {Moment} from 'moment';
import { RequestService } from '../request.service';
import { ModuleService } from '../module.service';

export abstract class PriorityStrategy {
  readonly abstract PRIORITY_NAME: string;
  protected timeTable: Timetable = null;
  protected requestService: RequestService;
  protected moduleService: ModuleService;
  protected constructor(requestService: RequestService, moduleService: ModuleService) {
    this.requestService = requestService;
    this.moduleService = moduleService;
  }

  can(): boolean {
    return !!this.timeTable && (!this.timeTable[this.PRIORITY_NAME] || this.timeTable[this.PRIORITY_NAME].length > 0);
  }

  setTimeTable(timeTable: Timetable): void {
    this.timeTable = timeTable;
  }

  abstract processing(): Media | null;

  protected convertDate(date: string): Moment {
    return moment(date.substr(0, date.length - 1));
  }
}
