import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { ScreenService, Screen } from '../screen.service';
import { Router } from '@angular/router';
import { trigger, transition, style, animate } from '@angular/animations';
import { MatSnackBar } from '@angular/material';
import { environment } from '@environment';
import { timer } from 'rxjs';

@Component({
  selector: 'app-connect',
  templateUrl: './connect.component.html',
  styleUrls: ['./connect.component.scss'],
  animations: [
    trigger('scaleFadeIn', [
      transition(':enter', [
        style({ opacity: '0', transform: 'scale(0)' }),
        animate('.5s ease-out', style({ opacity: '1', transform: 'scale(1)' })),
      ]),
    ]),
  ],
})
export class ConnectComponent implements OnInit, OnDestroy {

  uuid: string;
  uuidArray: Array<string>;
  screen: any;
  loading: any | string = null;
  error: any = null;
  screenId: string = null;
  screenSub: any;
  screenIdSub: any;
  uuid$: any;
  qrSize: number;
  orientationAngle: any;
  isWidescreen = false;
  animatingThumb = false;
  showReconnect = false;
  reconnectUUID: string;
  environment: any;

  constructor(
    private screenService: ScreenService,
    private router: Router,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.environment = environment;
    this.qrSize = 180;
    this.screenSub = this.screenService.screen.subscribe((screen) => {
      this.screen = screen;
      if (this.screen) {
        if (this.screen.connectedOn) {
          this.startThumbAnimation();
          const source = timer(2000);
          const animationTimer = source.subscribe((val) => {
            localStorage.setItem('entryUrl', 'player-v2');
            this.router.navigateByUrl('player-v2');
          });
        }
      } else {
        this.openSnackBar('UUID not found');
        this.screenService.uuid.next(this.screenService.generateUUID());
        this.createScreen();
      }
    });
    this.screenIdSub = this.screenService.screenId.subscribe((screenId) => {
      this.screenId = screenId;
    });
    this.uuid$ = this.screenService.uuid.subscribe((d) => {
      if (d) {
        this.uuid = d;
        this.uuidArray = d.split('-');
      }
    });
    if (!localStorage.getItem('screenId') || !this.screenService.getScreenFromLocalStorage()) {
      this.screenService.uuid.next(this.screenService.generateUUID()) ;
      this.createScreen();
    } else {
      const screenId = localStorage.getItem('screenId');
      const screen: Screen = this.screenService.getScreenFromLocalStorage();
      this.screenId = screenId;
      this.uuid = screen.uuid;
      this.uuidArray = screen.uuid.split('-');
      if (!screen.connectedOn) {
        this.screenService.subscribeToScreen(screenId);
      } else {
        this.router.navigateByUrl('player-v2');
      }
    }
  }

  ngOnDestroy(): void {
    // this.screenSub.unsubscribe();
    // this.screenIdSub.unsubscribe();
    // this.uuid$.unsubscribe();
  }

  createScreen() {
    this.loading = 'Decrypting Authkey...';
    const source = timer(1000);
    const timer$ = source.subscribe((val) => {
      this.loading = null;
      this.screenService.makeUUIDScreen();
    });
  }

  private generateUUID(): string {
    const isString = `${this.S4()}-${this.S4()}-${this.S4()}`;

    return isString;
  }

  private S4(): string {
    // tslint:disable-next-line: no-bitwise
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1).toUpperCase();
  }

  startThumbAnimation() {
    this.openSnackBar('Successfully synchronized ' + this.uuid);
    this.animatingThumb = true;
    const source = timer(2000);
    const timer$ = source.subscribe((val) => {
      this.animatingThumb = false;
    });
  }

  submitReconnectUUID() {
    const screen = {connectedOn: null, uuid: this.reconnectUUID};
    localStorage.setItem('screenId', this.reconnectUUID);
    localStorage.setItem('screen', JSON.stringify(screen));
    document.location.reload();
  }

  formatScreenCode() {
    const code = this.reconnectUUID;
    if (!code) {
      return;
    }
    if (code.length === 4 || code.length === 9) {
      const formatted = code.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1-').trim();
      this.reconnectUUID = formatted;
    }
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, null, {
      duration: 3000,
    });
  }

}
