import { Component, OnInit } from '@angular/core';
import { ModuleService } from 'src/app/player-v2/module.service';
import { MensaMaxRootObject, Tagesspeiseplaene } from 'src/app/player-v2/models/mensamax.class';
import * as moment from 'moment';

@Component({
  selector: 'app-mensamax',
  templateUrl: './mensamax.component.html',
  styleUrls: ['./mensamax.component.scss']
})
export class MensamaxComponent implements OnInit {

  data: MensaMaxRootObject;
  data$: any;
  dayMenu: Tagesspeiseplaene;

  constructor(
    private moduleService: ModuleService
  ) { }

  ngOnInit() {
    this.data = this.moduleService.mensaMaxData.value;
    this.setDayMenu();
    this.moduleService.mensaMaxData.subscribe((data) => {
      this.data = data;
      this.setDayMenu();
    });
  }

  setDayMenu() {

    const today = moment().set({ hour: 0, minute: 0, second: 0 }).format('YYYY-MM-DDTHH:mm:ss');
    this.dayMenu = this.data.Wochenspeiseplaene[0].Tagesspeiseplaene.find((t) => t.Datum === today);
  }

  hasDecimal(n: number) {
    return (n - Math.floor(n)) !== 0;
  }

}
