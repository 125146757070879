import { MediaItem } from './media-item.class';

export class Media {
    'mediaId': string;
    'mediaItems': Array<MediaItem>;
    'mediaTypeName': string; // "Slider", ..
    'multipleUsed': boolean;
    'multipleCount': 0;
    'customType': boolean;
    'mediaDescription': string;
    'mediaTypeId': string;
    'mediaItemSlideType': any;
    'isFavourite': boolean;
    'companyId': string;
}
