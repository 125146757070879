import { MediaItem } from './models/media-item.class';
import { BehaviorSubject } from 'rxjs';
import { MensaMaxRootObject } from './models/mensamax.class';

export class ModuleService {
  moduleMediaItems: BehaviorSubject<MediaItem[]> = new BehaviorSubject([]);
  isRequesting: BehaviorSubject<boolean> = new BehaviorSubject(false);
  mensaMaxData: BehaviorSubject<MensaMaxRootObject> = new BehaviorSubject(null);
  longpullingTimestamp: BehaviorSubject<Date> = new BehaviorSubject(null);
  constructor() {}
}
