import { trigger, style, animate, transition, state } from '@angular/animations';

export const fade = [
  trigger('fade', [
    state('in', style({ opacity: '1', transform: 'scale(1, 1)' })),
    state('out', style({ opacity: '0', transform: 'scale(1, 0.1)' })),
    transition('* <=> *', [
      animate(100)
    ])
  ])
];
