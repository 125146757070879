import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConnectComponent } from './connect/connect.component';
import { PlayerV2Component } from './player-v2/player-v2.component';

import { HttpClientModule } from '@angular/common/http';
import { ApiService } from './player-v2/api.service';
import { RequestService } from './player-v2/request.service';
import { NativeTriggerComponent } from './native-trigger/native-trigger.component';
import { ConnectGuardService } from './connect/connect.guard';
import { SliderComponent } from './slider/slider.component';
import { VideoComponent } from './video/video.component';
import { MatVideoModule } from 'mat-video';
import { QRCodeModule } from 'angular2-qrcode';
import { VgCoreModule } from 'videogular2/compiled/core';
import { VgControlsModule } from 'videogular2/compiled/controls';
import { VgOverlayPlayModule } from 'videogular2/compiled/overlay-play';
import { VgBufferingModule } from 'videogular2/compiled/buffering';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import {MatInputModule} from '@angular/material';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { FullscreenTogglerComponent } from './fullscreen-toggler/fullscreen-toggler.component';
import { YoojisApiService } from './player-v2/yoojis-api.service';
import { IaffmpegService } from './player-v2/iaffmpeg.service';
import { ModuleService } from './player-v2/module.service';
import { MensaMaxApiService } from './player-v2/mensamax-api.service';
import { MensamaxComponent } from './module/mensamax/mensamax.component';
import { CurrencyNumberPipe } from './currency-number.pipe';

@NgModule({
  declarations: [
    AppComponent,
    ConnectComponent,
    PlayerV2Component,
    NativeTriggerComponent,
    SliderComponent,
    VideoComponent,
    FullscreenTogglerComponent,
    MensamaxComponent,
    CurrencyNumberPipe
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule.enablePersistence({ synchronizeTabs: true}),
    HttpClientModule,
    MatVideoModule,
    QRCodeModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production, registrationStrategy: 'registerWithDelay' }),
    MatIconModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    MatSnackBarModule,
    DeviceDetectorModule.forRoot()
  ],
  providers: [
    ApiService,
    RequestService,
    ConnectGuardService,
    YoojisApiService,
    IaffmpegService,
    MensaMaxApiService,
    ModuleService,
    CurrencyNumberPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
