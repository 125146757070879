import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-native-trigger',
  templateUrl: './native-trigger.component.html',
  styleUrls: ['./native-trigger.component.scss']
})
export class NativeTriggerComponent implements OnInit {

  trigger: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() { }

  navigateToTrigger(t: string) {
    this.router.navigateByUrl('native-trigger/' + t);
  }

}
