export const environment = {
    production: true,
    identifier: 'staging',
    commitNumber: 0,
    screenManager: {
      baseUrl: 'https://manager.screenlime.app/#/',
      connectPath: 'connect/',
      screenPath: 'screen/'
    },
    api_url: 'https://screenlime.azurewebsites.net/api',
    azureCDN: 'https://media.screenlime.ch/',
    firebase: {
      apiKey: 'AIzaSyBTni4gEXSjY-grotEtqRqzPqWCUxd5pLg',
      authDomain: 'screenlime.firebaseapp.com',
      databaseURL: 'https://screenlime.firebaseio.com',
      projectId: 'screenlime',
      storageBucket: 'screenlime.appspot.com',
      messagingSenderId: '552745022623',
      appId: '1:552745022623:web:4a3c94e425114256371eaa'
    },
    yoojisBaseUrl: 'https://yoo3.azurewebsites.net/api',
    //iaffmpegBaseUrl: 'https://35.204.246.81:3000',
    iaffmpegBaseUrl: 'https://ffmpegpublisher.inaffect.one',
    mensaMaxBaseUrl: 'https://web2020.screenlime.ch'
  };
