import {PriorityStrategy} from './priority-strategy.abstract';
import {Media} from '../models/media.class';
import {TimetableEntry} from '../types/timetable-entry.type';
import * as moment from 'moment';
import {Moment} from 'moment';
import { Inject } from '@angular/core';
import { RequestService } from '../request.service';
import { ModuleService } from '../module.service';

export class WeekPriorityStrategy extends PriorityStrategy {
  readonly PRIORITY_NAME = 'week';
  constructor(
    @Inject(RequestService) requestService,
    @Inject(ModuleService) moduleService
  ) {
    super(requestService, moduleService);
  }
  processing(): Media | null {
    const entries: TimetableEntry[] = this.timeTable[this.PRIORITY_NAME];
    const currentDay = moment().day();
    const now: Moment = moment().set({date: 1, month: 0, year: 1900});

    for (const entry of entries) {
      if (currentDay !== parseInt(entry.timeTableValue, 10)) {
        continue;
      }

      const start = this.convertDate(entry.startTime);
      const end = this.convertDate(entry.endTime);
      if (start.isBefore(now) && end.isAfter(now)) {
        return entry.media;
      }
    }

    return null;
  }

}
