import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MediaItem } from '../player-v2/models/media-item.class';
import { fade } from '../animations';
import { timer } from 'rxjs';
import { interval } from 'rxjs';
import { environment } from '@environment';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  animations: fade
})
export class SliderComponent implements OnInit, OnDestroy {
  sliderInterval: any;
  sliderIndex = 0;
  state = 'in';
  azureCDN = environment.azureCDN;
  videoMediaItems: MediaItem[];

  @Input() mediaItems: MediaItem[];
  @Input() useLocalSrc: boolean;

  constructor() { }

  ngOnInit() {
    this.startSlider(this.mediaItems);
  }

  ngOnDestroy() {
    this.stopSlider();
  }

  startSlider(mediaItems: MediaItem[]) {
    this.stopSlider();
    console.log('Index: ', this.sliderIndex);
    const fileExt = this.returnFileExt(mediaItems[this.sliderIndex].filename);
    console.log('Ext: ', fileExt);
    if (fileExt === 'mp4') {
      // pause slider
      this.makeVideoItems(mediaItems);
      this.stopSlider();
      return;
    }
    const interval1 = interval(mediaItems[0].showSeconds * 1000);
    this.sliderInterval = interval1.subscribe((val) => {
      console.log('Index in Sub1: ', this.sliderIndex);
      if (this.returnFileExt(mediaItems[this.sliderIndex].filename) === 'mp4') {
        // pause slider
        this.makeVideoItems(mediaItems);
        this.stopSlider();
        return;
      }
      if (this.sliderIndex < mediaItems.length - 1) {
        this.sliderIndex += 1;
        console.log('Index in Sub1: ', this.sliderIndex);
        this.stopSlider();
        this.startSlider(mediaItems);
      } else {
        this.sliderIndex = 0;
        this.stopSlider();
        this.startSlider(mediaItems);
      }
      console.log('Index in Sub2: ', this.sliderIndex);
      const timer1 = timer(100);
      const timer2 = timer(mediaItems[0].showSeconds * 1000 - 140);
      const timer1$ = timer1.subscribe((val) => {
        this.state = 'in';
      });
      const timer2$ = timer2.subscribe((val) => {
        this.state = 'out';
      });
    });
  }

  makeVideoItems(mediaItems) {
    const videoItems = [];
    this.videoMediaItems = null;
    videoItems.push(mediaItems[this.sliderIndex]);
    setTimeout(() => {
      this.videoMediaItems = videoItems;
    }, 20);
  }

  stopSlider() {
    if (this.sliderInterval) {
      console.log('Unsubscribing');
      this.sliderInterval.unsubscribe();
    }
  }

  getCDNUrl(publicUrl) {
    // Why? because we dont have the companyId: https:/....net/[[companyId]]/[[fileId]]
    return publicUrl.replace('https://iascreenmanager.blob.core.windows.net/', environment.azureCDN);
  }

  returnFileExt(filename: string) {
    // get file extension
    const splitted = filename.split('.');
    return splitted[splitted.length - 1].toLowerCase();
  }

  onPlayerEnded() {
    this.videoMediaItems = null;
    // skip video slide
    if (this.sliderIndex < this.mediaItems.length - 1) {
      this.sliderIndex += 1;
    } else {
      this.sliderIndex = 0;
    }
    const mediaItems = this.mediaItems;
    const fileExt = this.returnFileExt(mediaItems[this.sliderIndex].filename);
    if (fileExt === 'mp4') {
      this.makeVideoItems(mediaItems);
    } else {
      this.startSlider(this.mediaItems);
    }
  }

}
